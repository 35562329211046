import { apiClient } from "./apiClient";
import apiUltils from "./apiUltils";

export default {
  getDescriptors(queryObjectType, params, orgParam) {
    return apiClient.get(
      apiUltils.buildParamsListURI(
        `/rest/search/gui/basic/filter/descriptors/${queryObjectType}` +
          (orgParam || ""),
        params
      )
    );
  },

  getFilterColumns(queryObjectType, params) {
    return apiClient.get(
      apiUltils.buildParamsListURI(
        `/rest/search/gui/table/descriptors/${queryObjectType}`,
        params
      )
    );
  },

  getSuggestion(params) {
    return apiClient.get(`/rest${params.url}`);
  },

  getSuggestionActivityState() {
    return apiClient.get(
      `/rest/search/gui/filter/suggestion/lastActivityState`,
      {}
    );
  },

  getSuggestionThirdPartyEnum(filterKey, params) {
    return apiClient.post(
      `/rest/search/gui/filter/suggestion/enum/${filterKey}`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getSuggestionGeozoneByLastGeozoneTags(params) {
    return apiClient.post(
      `/rest/search/gui/filter/suggestion/geozoneFiltered/lastGeozoneTags`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getSuggestionDeviceByLastGeozoneTags(params) {
    return apiClient.post(
      `/rest/search/gui/filter/suggestion/deviceFiltered/lastGeozoneTags`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getSystemTags(params) {
    return apiClient.get(
      `/rest/search/gui/filter/suggestion/device/systemTags`,
      { params: params }
    );
  },

  getPartnerTags(params) {
    return apiClient.get(
      `/rest/search/gui/filter/suggestion/device/partnerTags`,
      { params: params }
    );
  },

  searchCount(params) {
    return apiClient.post("/rest/search/count", params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
