import $ from "jquery";
import React, { useEffect, useRef } from "react";
import { ButtonGroup, Dropdown, Form, Pagination } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import { Sticky, StickyContainer } from "react-sticky";
import styled from "styled-components";
import IcomoonIcon from "../../Icons/IcomoonIcon";
import LoaderSpinner from "../../ReactBootstrap/LoaderSpinner/LoaderSpinner";
import SntActionButton from "../../ReactBootstrap/SntActionButton";
import { useDataFilterTable } from "../hooks/useDataFilterTable";
import SntLink from "../../ReactBootstrap/SntLink";
import SntSmallMenu from "../../Icons/SntSmallMenu";
import SntGroupBy from "../../Icons/SntGroupBy";
import SntUnGroup from "../../Icons/SntUnGroup";
import SntViewerDisable from "../../Icons/SntViewerDisable";
import SntSortAZ from "../../Icons/SntSortAZ";
import SntSortZA from "../../Icons/SntSortZA";
import {
  dateBucketTypes,
  supportedGroupTypeConstant,
} from "@/components/SntTableViewCard/SntTableConfigurationConstant.js";
import SntExportIcon from "@/components/Icons/SntExportIcon";
import AwesomeIcon from "@/components/Icons/AwesomeIcon";
import ElasticKibanaIcon from "@images/elastic-kibana.svg";
import GlobalUtils from "@/utils/GlobalUtils";
import NoDataContainer from "@/cleanup/containers/NoData/NoDataContainer";

const Row = styled.div`
  .td:first-child {
    ${(props) =>
      props.isShowDecoratorColor
        ? `border-left: 5px solid ${props.decoratorColor || "transparent"}`
        : ""};
    ${(props) => (props.isShowDecoratorColor ? "padding-left: 0.25rem" : "")};
  }
`;

// const Row = styled.div`
//   .td:first-child {
//     ${(props) =>
//       props.isShowDecoratorColor
//         ? `border-left: 5px solid ${props.decoratorColor || "transparent"}`
//         : ""};
//     ${(props) => (props.isShowDecoratorColor ? "padding-left: 0.25rem" : "")};
//   }
// `;

const Styles = styled.div`
  ${"" /* These styles are suggested for the table fill all available space in its containing element */}

  ${"" /* These styles are required for a horizontaly scrollable table overflow */}
  overflow: auto;
  position: relative;

  .table {
    border-spacing: 0;
    margin-bottom: 60px;
    min-height: 300px;
    &.dynamic {
      min-height: 1px;
    }
    .tbody {
      ${"" /* These styles are required for a scrollable table body */}
      overflow: overlay;
    }

    .tr {
      &.odd {
        background: #f9fafa;
        .td {
          background: #f9fafa;
        }
      }
      &.even {
        background: #fff;
      }
    }

    .mh-30 {
      min-height: 30px;
    }

    .th {
      font-weight: bold;
      border-bottom: 2px solid #f3f5f6;
      padding: 0.5rem;

      .resizer {
        display: inline-block;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;
        border-left: 1px solid #424b59 !important;
        cursor: e-resize !important;
        opacity: 0;

        :hover {
          opacity: 0.5;
        }

        &.isResizing {
          opacity: 0.5;
        }
      }

      .group-header-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #eff3f4;
      }

      .sortIcon {
        color: grey;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #eff3f4;
      }

      .sortIconHover {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #eff3f4;
        opacity: 0;
      }

      &:hover {
        .table-group {
          opacity: 1;
        }

        .sortIcon {
          color: unset;
        }

        .sortIconHover {
          opacity: 1;
        }
      }
      .table-group {
        display: inline-block;
        opacity: 0;
        padding: 0px 0.5rem;
        &.show {
          opacity: 1;
        }

        .table-group-menu {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #eff3f4;
        }
      }
    }

    .td {
      word-break: break-word;
    }
  }
`;
/**
 * 
 * @param {*} props:
 *  dataFilter,
    data,
    onConfigColumn,
    loading,
    noDataText,
    isShowSelectPageSize = true,
    isFloatingFooter = true,
    isShowPaging = true,
    isShowDecoratorColor = false,
    isDisabbledGroup = true,
    onRenderConfig,
    isDynamicDashBoard,
    height
 * @returns 
 */
export const SntDataTable = (props) => {
  const { isDynamicDashBoard } = props;

  if (isDynamicDashBoard) return <DynamicTable {...props} />;
  return <StickyTable {...props} />;
};

const StickyTable = ({
  dataFilter,
  data,
  onConfigColumn,
  loading,
  noDataText,
  isShowSelectPageSize = true,
  isFloatingFooter = true,
  isShowPaging = true,
  isShowDecoratorColor = false,
  isDisabbledGroup = true,
  onRenderConfig,
  onExtraColumnActionClicked,
  getCopyData,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      // minWidth: 50,
      // width: 150,
      maxWidth: 600,
    }),
    []
  );

  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  const { features } = loginInfo;

  const table = useDataFilterTable(dataFilter, data, {
    defaultColumn: defaultColumn,
  });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    totalItems,
    toggleSortBy,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = table;

  const { width, ref } = useResizeDetector();
  const containerRef = useRef();
  // floating pager
  useEffect(() => {
    const el = containerRef.current;
    if (isFloatingFooter) {
      if ($(el).find(".fl-scrolls").length < 1) {
        $(el).find(".table-container").floatingScroll();
      }
    }
    return () => {
      $(el).find(".table-container").floatingScroll("destroy");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (isFloatingFooter) {
      setTimeout(() => {
        const el = containerRef.current;

        $(el).find(".table-container").floatingScroll("update");
        let flScrollsEl = $(".fl-scrolls");
        flScrollsEl.css(
          "margin-bottom",
          $(el).find(".table-footer").height() || "51px"
        );
        $(el)
          .find(".table-container")
          .css(
            "margin-bottom",
            Number.parseInt($(".table-footer").height()) - 27 + "px" || "51px"
          );
      }, 10);
    }
    if (width) {
      const el = containerRef.current;
      $(el).find(".table-footer").width(width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, loading]);

  const getGeneralCopyData = (data, key) => {
    let list = [];

    if (data[key]) {
      list.push(data[key]);
    }

    return list;
  };

  const renderHeader = () => {
    return (
      <div style={{ backgroundColor: "white", padding: "0px", zIndex: 99 }}>
        {headerGroups.map((headerGroup, idx) => (
          <div
            key={idx}
            {...headerGroup.getHeaderGroupProps()}
            className="tr mh-30"
          >
            {onConfigColumn && (
              <div
                className={`th align-items-center`}
                style={getConfigColumnStyle()}
              >
                <SntActionButton
                  title={language.table_settings_key}
                  onClick={(e) => {
                    e.preventDefault();
                    onConfigColumn();
                  }}
                >
                  <IcomoonIcon
                    icon="cog"
                    color="#0071a1"
                    size="16"
                  ></IcomoonIcon>
                </SntActionButton>
              </div>
            )}

            {headerGroup.headers.map((column, key) => {
              if (column.isResizing && isFloatingFooter) {
                $(containerRef.current)
                  .find(".table-container")
                  .floatingScroll("update");
              }
              return (
                <div
                  key={key}
                  {...column.getHeaderProps(headerProps)}
                  className={`th align-items-center justify-content-between`}
                >
                  <div className="d-flex align-items-center">
                    <div
                      {...column.getSortByToggleProps({
                        title: column.description,
                      })}
                      onClick={() => {
                        let desc = false;
                        if (column.isSorted) {
                          desc = !column.isSortedDesc;
                        }
                        !column.disableSortBy &&
                          toggleSortBy(column.columnKey, desc);
                      }}
                    >
                      {column.isGrouped && (
                        <span style={{ fontWeight: "normal" }}>
                          {language.group_by_key}:{" "}
                        </span>
                      )}
                      {column.render("Header")}
                    </div>

                    {!column.disableSortBy && (
                      <div
                        className="ms-2"
                        {...column.getSortByToggleProps({
                          title: column.description,
                        })}
                        onClick={() => {
                          let desc = false;
                          if (column.isSorted) {
                            desc = !column.isSortedDesc;
                          }
                          toggleSortBy(column.columnKey, desc);
                        }}
                      >
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <span className="sortIcon">
                              <SntSortZA />
                            </span>
                          ) : (
                            <span className="sortIcon">
                              <SntSortAZ />
                            </span>
                          )
                        ) : (
                          <span className="sortIconHover">
                            <SntSortAZ />
                          </span>
                        )}
                      </div>
                    )}

                    {column.isGrouped && (
                      <div className="ms-2" style={{ cursor: "pointer" }}>
                        <span
                          className="group-header-icon"
                          title={language.ungroup_column_key}
                          onClick={() => {
                            dataFilter.toggleGroupColumnKey(column.key);
                          }}
                        >
                          <SntUnGroup />
                        </span>
                      </div>
                    )}
                  </div>

                  {!column.immovable &&
                    (!column.disableSortBy ||
                      (features["search_aggregate"] &&
                        !isDisabbledGroup &&
                        !column.disabledGroupColumn) ||
                      onConfigColumn) && (
                      <Dropdown
                        className={`hide-arrow table-group`}
                        size="md"
                        style={{ zIndex: 1002 }}
                        as={ButtonGroup}
                      >
                        <Dropdown.Toggle
                          as={SntLink}
                          className="table-group-menu"
                        >
                          <SntSmallMenu />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {!column.disableSortBy && (
                            <>
                              <Dropdown.Header>
                                {language.columm_actions_key}
                              </Dropdown.Header>
                              <Dropdown.Item
                                bsPrefix="dropdown-item"
                                onClick={() => {
                                  toggleSortBy(column.columnKey, false);
                                }}
                              >
                                <div className="d-flex">
                                  <div
                                    style={{ width: 20 }}
                                    className="d-flex align-items-center"
                                  >
                                    <SntSortAZ />
                                  </div>
                                  <div>{language.sort_az_key}</div>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                bsPrefix="dropdown-item"
                                onClick={() => {
                                  toggleSortBy(column.columnKey, true);
                                }}
                              >
                                <div className="d-flex">
                                  <div
                                    style={{ width: 20 }}
                                    className="d-flex align-items-center"
                                  >
                                    <SntSortZA />
                                  </div>
                                  <div>{language.sort_za_key}</div>
                                </div>
                              </Dropdown.Item>
                              {((features["search_aggregate"] &&
                                !isDisabbledGroup &&
                                !column.disabledGroupColumn) ||
                                onConfigColumn) && <Dropdown.Divider />}
                            </>
                          )}

                          {features["search_aggregate"] &&
                            !isDisabbledGroup &&
                            !column.disabledGroupColumn && (
                              <>
                                {column.supportedGroupType ===
                                supportedGroupTypeConstant.DATE_BUCKET ? (
                                  <>
                                    {dateBucketTypes.map((bucketType) => {
                                      let desc = false;
                                      if (column.isSorted) {
                                        desc = column.isSortedDesc;
                                      }
                                      return (
                                        <Dropdown.Item
                                          key={bucketType}
                                          bsPrefix="dropdown-item"
                                          onClick={() => {
                                            if (
                                              column.bucketType === bucketType
                                            ) {
                                              dataFilter.toggleGroupColumnKey(
                                                column.key
                                              );
                                            } else {
                                              dataFilter.setColumnsSetting(
                                                headerGroup.headers.map(
                                                  (c) => c.key
                                                ),
                                                {
                                                  key: column.key,
                                                  desc: desc,
                                                },
                                                column.key,
                                                bucketType
                                              );
                                            }
                                          }}
                                        >
                                          <div className="d-flex">
                                            <div
                                              style={{ width: 20 }}
                                              className="d-flex align-items-center"
                                            >
                                              {column.isGrouped &&
                                              column.bucketType ===
                                                bucketType ? (
                                                <SntUnGroup />
                                              ) : (
                                                <SntGroupBy />
                                              )}
                                            </div>
                                            <div>
                                              {column.isGrouped &&
                                              column.bucketType === bucketType
                                                ? language[
                                                    "ungroup_column_" +
                                                      bucketType.toLowerCase()
                                                  ]
                                                : language[
                                                    "group_by_" +
                                                      bucketType.toLowerCase()
                                                  ]}
                                            </div>
                                          </div>
                                        </Dropdown.Item>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <Dropdown.Item
                                    bsPrefix="dropdown-item"
                                    onClick={() => {
                                      dataFilter.toggleGroupColumnKey(
                                        column.key
                                      );
                                    }}
                                  >
                                    <div className="d-flex">
                                      <div
                                        style={{ width: 20 }}
                                        className="d-flex align-items-center"
                                      >
                                        {column.isGrouped ? (
                                          <SntUnGroup />
                                        ) : (
                                          <SntGroupBy />
                                        )}
                                      </div>
                                      <div>
                                        {column.isGrouped
                                          ? language.ungroup_column_key
                                          : language.group_column_key}
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                )}

                                {onConfigColumn && <Dropdown.Divider />}
                              </>
                            )}

                          {(onExtraColumnActionClicked || getCopyData) &&
                            !column.isGrouped && (
                              <>
                                {getCopyData && (
                                  <Dropdown.Item
                                    bsPrefix="dropdown-item"
                                    onClick={() => {
                                      let list = [];

                                      rows.forEach((row, i) => {
                                        prepareRow(row);

                                        let cellValue = [];
                                        if (getCopyData) {
                                          cellValue = getCopyData(
                                            row.original,
                                            column.key
                                          );
                                        } else {
                                          cellValue = getGeneralCopyData(
                                            row.original,
                                            column.key
                                          );
                                        }
                                        list = [...list, ...cellValue];
                                      });

                                      // Convert the array to a Set to remove duplicates
                                      const uniqueSet = new Set(list);

                                      // Convert the Set back to an array using the spread operator
                                      const uniqueList = [...uniqueSet];
                                      navigator.clipboard.writeText(
                                        uniqueList.join(", ")
                                      );
                                    }}
                                  >
                                    <div className="d-flex">
                                      <div
                                        style={{ width: 20 }}
                                        className="d-flex align-items-center"
                                      >
                                        <AwesomeIcon
                                          icon={["far", "copy"]}
                                        ></AwesomeIcon>
                                      </div>
                                      <div>
                                        {language.copy_clumn_clipboard_key}
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                )}
                                {column.supportKibanaKey && (
                                  <Dropdown.Item
                                    bsPrefix="dropdown-item"
                                    onClick={() => {
                                      let list = [];

                                      rows.forEach((row, i) => {
                                        prepareRow(row);

                                        let cellValue = [];
                                        if (getCopyData) {
                                          cellValue = getCopyData(
                                            row.original,
                                            column.key
                                          );
                                        } else {
                                          cellValue = getGeneralCopyData(
                                            row.original,
                                            column.key
                                          );
                                        }
                                        list = [...list, ...cellValue];
                                      });

                                      // Convert the array to a Set to remove duplicates
                                      const uniqueSet = new Set(list);

                                      // Convert the Set back to an array using the spread operator
                                      const uniqueList = [...uniqueSet];
                                      let url = GlobalUtils.getKibanaLinkByFilter(
                                        "now-30m",
                                        "now",
                                        column.supportKibanaKey,
                                        uniqueList,
                                        "desc"
                                      );
                                      window.open(url, "_blank");
                                    }}
                                  >
                                    <div className="d-flex">
                                      <div
                                        style={{ width: 20 }}
                                        className="d-flex align-items-center"
                                      >
                                        <img
                                          alt={""}
                                          src={ElasticKibanaIcon}
                                          style={{ width: 16, height: 16 }}
                                        />
                                      </div>
                                      <div>
                                        {"Go to kibana with column data filter"}
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                )}
                                {onExtraColumnActionClicked && (
                                  <Dropdown.Item
                                    bsPrefix="dropdown-item"
                                    onClick={() => {
                                      onExtraColumnActionClicked({
                                        columnKey: column.key,
                                        action: "export",
                                      });
                                    }}
                                  >
                                    <div className="d-flex">
                                      <div
                                        style={{ width: 20 }}
                                        className="d-flex align-items-center"
                                      >
                                        <SntExportIcon />
                                      </div>
                                      <div>
                                        {language.export_full_column_key}
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                )}
                                <Dropdown.Divider />
                              </>
                            )}

                          {onConfigColumn && (
                            <>
                              <Dropdown.Item
                                bsPrefix="dropdown-item"
                                onClick={() => {
                                  dataFilter.removeColumnKey(column.key);
                                }}
                              >
                                <div className="d-flex">
                                  <div
                                    style={{ width: 20 }}
                                    className="d-flex align-items-center"
                                  >
                                    <SntViewerDisable />
                                  </div>
                                  <div>{language.hide_column_key}</div>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                bsPrefix="dropdown-item"
                                onClick={(e) => {
                                  e.preventDefault();
                                  onConfigColumn();
                                }}
                              >
                                <div className="d-flex">
                                  <div
                                    style={{ width: 20 }}
                                    className="d-flex align-items-center"
                                  >
                                    <IcomoonIcon
                                      icon="cog"
                                      size="16"
                                    ></IcomoonIcon>
                                  </div>
                                  <div>{language.table_settings_key}</div>
                                </div>
                              </Dropdown.Item>
                            </>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? "isResizing" : ""
                    }`}
                  />
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  };

  const tableProps = getTableProps();
  tableProps.style = tableProps.style || {};
  const existingMinWidth = parseInt(tableProps.style.minWidth || "0", 10);
  tableProps.style.minWidth = existingMinWidth + 40 + "px"; //this 40 value is config column size

  return (
    <div ref={containerRef}>
      <StickyContainer>
        <Styles>
          <div className="table-container" ref={ref}>
            <div {...tableProps} className="table">
              <Sticky>
                {({
                  style,
                  isSticky,
                  // the following are also available but unused
                  // see https://github.com/captivationsoftware/react-sticky
                  // wasSticky,
                  // distanceFromTop,
                  // distanceFromBottom,
                  // calculatedHeight,
                }) => (
                  <div
                    className="full-screen-table"
                    style={{
                      ...style,
                      marginTop: isSticky ? "50px" : "0px",
                      zIndex: isSticky ? 100 : "auto",
                    }}
                  >
                    {renderHeader()}
                    {/* end Header Table */}
                  </div>
                )}
              </Sticky>

              {/* end Header Table */}

              <div className="tbody" {...getTableBodyProps()}>
                {rows.length === 0 && !loading ? (
                  <></>
                ) : (
                  rows.map((row, i) => {
                    prepareRow(row);

                    let decoratorColor = "";
                    if (isShowDecoratorColor) {
                      decoratorColor =
                        row.original && row.original.decoratorColor
                          ? row.original.decoratorColor
                          : null;
                    }

                    return (
                      <Row
                        key={i}
                        {...row.getRowProps()}
                        style={{
                          ...row.getRowProps().style,
                          overflow: "hidden",
                        }}
                        className={`tr ${i % 2 ? "even" : "odd"}`}
                        decoratorColor={decoratorColor}
                        isShowDecoratorColor={isShowDecoratorColor}
                      >
                        {onConfigColumn && (
                          <div style={getConfigColumnStyle()} className={"td"}>
                            <div>
                              {!table.isGroupedMode &&
                                onRenderConfig &&
                                onRenderConfig(row.original)}
                            </div>
                          </div>
                        )}
                        {row.cells.map((cell, index) => {
                          return (
                            <div
                              key={index}
                              {...cell.getCellProps(cellProps)}
                              className={"td"}
                            >
                              <div className={`${cell.column.cellClass || ""}`}>
                                {cell.render("Cell")}
                              </div>
                            </div>
                          );
                        })}
                      </Row>
                    );
                  })
                )}
              </div>
              {rows.length === 0 && (
                <NoDataContainer noDataText={noDataText} loading={loading} />
              )}
              {/* End Body Table */}
            </div>
            {/* End Table */}
          </div>

          <div style={{ marginBottom: "5px" }}>&nbsp;</div>

          {isShowPaging && (
            <div
              className={`table-footer ${
                isFloatingFooter ? "floating-footer" : ""
              }`}
            >
              <div
                style={{
                  padding: isFloatingFooter && "0.5rem",
                  paddingBottom: isFloatingFooter && "0px",
                }}
              >
                <SntTablePaging
                  gotoPage={gotoPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  pageCount={pageCount}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  onConfigColumn={onConfigColumn}
                  isShowSelectPageSize={isShowSelectPageSize}
                  totalItems={totalItems}
                  headerGroups={headerGroups}
                />
              </div>
            </div>
          )}

          <LoaderSpinner loading={loading} />
        </Styles>
      </StickyContainer>
    </div>
  );
};

const DynamicTable = ({
  dataFilter,
  data,
  loading,
  noDataText,
  isShowSelectPageSize = true,
  isShowPaging = true,
  isShowDecoratorColor = false,
  onRenderConfig,
  height,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      // minWidth: 50,
      // width: 150,
      maxWidth: 600,
    }),
    []
  );

  // eslint-disable-next-line no-unused-vars
  const { isMobile } = useSelector((state) => state.all);

  const language = useSelector((state) => state.language);

  const table = useDataFilterTable(dataFilter, data, {
    defaultColumn: defaultColumn,
  });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    totalItems,
    toggleSortBy,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = table;

  const tableProps = getTableProps();
  tableProps.style = tableProps.style || {};
  const existingMinWidth = parseInt(tableProps.style.minWidth || "0", 10);
  tableProps.style.minWidth = existingMinWidth + 40 + "px"; //this 40 value is config column size

  return (
    <Styles>
      <div
        className="table-container"
        style={{
          height: `calc(${height}px - 2rem - 35px - 20px  ${
            isMobile ? "- 30px" : ""
          })`,
          overflowY: "hidden",
        }}
      >
        <div {...tableProps} className="table dynamic mb-0">
          <div className="full-screen-table">
            <div
              style={{ backgroundColor: "white", padding: "0px", zIndex: 99 }}
            >
              {headerGroups.map((headerGroup, idx) => (
                <div
                  key={idx}
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr mh-30"
                >
                  {!table.isGroupedMode && onRenderConfig && (
                    <div
                      className={`th align-items-center`}
                      style={getConfigColumnStyle()}
                    ></div>
                  )}

                  {headerGroup.headers.map((column, key) => {
                    return (
                      <div
                        key={key}
                        {...column.getHeaderProps(headerProps)}
                        className={`th align-items-center justify-content-between`}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ width: "calc(100% - 20px)" }}
                        >
                          <div
                            {...column.getSortByToggleProps({
                              title: column.description,
                            })}
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            onClick={() => {}}
                          >
                            {column.isGrouped && (
                              <span style={{ fontWeight: "normal" }}>
                                {language.group_by_key}:{" "}
                              </span>
                            )}
                            {column.render("Header")}
                          </div>

                          {!column.disableSortBy && (
                            <div
                              className="ms-2"
                              {...column.getSortByToggleProps({
                                title: column.description,
                              })}
                              onClick={() => {
                                let desc = false;
                                if (column.isSorted) {
                                  desc = !column.isSortedDesc;
                                }
                                toggleSortBy(column.columnKey, desc);
                              }}
                            >
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <span className="sortIcon">
                                    <SntSortZA />
                                  </span>
                                ) : (
                                  <span className="sortIcon">
                                    <SntSortAZ />
                                  </span>
                                )
                              ) : (
                                <span className="sortIcon">
                                  <SntSortAZ />
                                </span>
                              )}
                            </div>
                          )}
                        </div>

                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${
                            column.isResizing ? "isResizing" : ""
                          }`}
                        />
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            {/* end Header Table */}
          </div>
          {/* end Header Table */}

          {rows.length === 0 ? (
            <NoDataContainer noDataText={noDataText} loading={loading} />
          ) : (
            <div
              className="tbody"
              {...getTableBodyProps()}
              style={{
                overflowY: "auto",
                height: `calc(${height}px - 2rem - 55px - 35px - 50px ${
                  isMobile ? "- 30px" : ""
                })`,
              }}
            >
              {!loading &&
                rows.map((row, i) => {
                  prepareRow(row);

                  let decoratorColor = "";
                  if (isShowDecoratorColor) {
                    decoratorColor =
                      row.original && row.original.decoratorColor
                        ? row.original.decoratorColor
                        : null;
                  }

                  return (
                    <Row
                      key={i}
                      {...row.getRowProps()}
                      style={{ ...row.getRowProps().style, overflow: "hidden" }}
                      className={`tr ${i % 2 ? "even" : "odd"}`}
                      decoratorColor={decoratorColor}
                      isShowDecoratorColor={isShowDecoratorColor}
                    >
                      {!table.isGroupedMode && onRenderConfig && (
                        <div style={getConfigColumnStyle()} className={"td"}>
                          <div>
                            {onRenderConfig && onRenderConfig(row.original)}
                          </div>
                        </div>
                      )}
                      {row.cells.map((cell, index) => {
                        return (
                          <div
                            key={index}
                            {...cell.getCellProps(cellProps)}
                            className={"td"}
                          >
                            <div className={`${cell.column.cellClass || ""}`}>
                              {cell.render("Cell")}
                            </div>
                          </div>
                        );
                      })}
                    </Row>
                  );
                })}
            </div>
          )}
          {/* End Body Table */}
        </div>
        {/* End Table */}
      </div>

      {isShowPaging && (
        <div className={`table-footer`}>
          <div
            style={{
              paddingTop: "0.5rem",
            }}
          >
            <SntTablePaging
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageIndex={pageIndex}
              pageOptions={pageOptions}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isShowSelectPageSize={isShowSelectPageSize}
              totalItems={totalItems}
              headerGroups={headerGroups}
            />
          </div>
        </div>
      )}

      <LoaderSpinner loading={loading} />
    </Styles>
  );
};

const SntTablePaging = ({
  gotoPage,
  previousPage,
  nextPage,
  pageCount,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
  isShowSelectPageSize,
  totalItems,
}) => {
  let visiblePages = getVisiblePages(pageIndex, pageCount);
  const language = useSelector((state) => state.language);
  let visibleTotal = getTotalItemView(totalItems, pageSize, pageIndex);

  return (
    <>
      <div>
        <div className="d-block d-md-inline-block float-md-start pt-2 mb-2">
          {totalItems && totalItems.countAll > 0 ? (
            <span>
              {language.showing_key}{" "}
              <strong>
                {visibleTotal.from} - {visibleTotal.to}
                {"/"}
                {totalItems.countByFilter} {language.items_key}
              </strong>{" "}
            </span>
          ) : (
            <span>
              {language.page_key}{" "}
              <strong>
                {pageOptions.length === 0 ? 0 : pageIndex + 1} of{" "}
                {pageOptions.length}
              </strong>{" "}
            </span>
          )}
        </div>

        <div className="d-block d-md-inline-block float-md-end mb-2">
          {isShowSelectPageSize && (
            <div className="d-inline-block me-3">
              <Form.Select
                size="md"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[25, 50, 100, 200].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {language.show_key} {pageSize}
                  </option>
                ))}
              </Form.Select>
            </div>
          )}

          <div className="d-inline-block float-end">
            <Pagination className="mb-0 align-self-center">
              <Pagination.Prev
                disabled={!canPreviousPage}
                onClick={() => canPreviousPage && previousPage()}
              />
              {visiblePages.map((page, index, array) => {
                if (array[index - 1] + 2 < page) {
                  return (
                    <React.Fragment key={index}>
                      <Pagination.Ellipsis />
                      <Pagination.Item
                        active={pageIndex === page - 1}
                        onClick={() => gotoPage(page - 1)}
                      >
                        {page}
                      </Pagination.Item>
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={index}>
                      <Pagination.Item
                        active={pageIndex === page - 1}
                        onClick={() => gotoPage(page - 1)}
                      >
                        {page}
                      </Pagination.Item>
                    </React.Fragment>
                  );
                }
              })}
              <Pagination.Next
                disabled={!canNextPage}
                onClick={() => canNextPage && nextPage()}
              />
            </Pagination>
          </div>
        </div>
      </div>
    </>
  );
};

const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: align === "right" ? "flex-end" : "flex-start",
      alignItems: "flex-start",
      display: "flex",
    },
  },
];

const filterPages = (visiblePages, totalPages) => {
  return visiblePages.filter((page) => page <= totalPages);
};

const getVisiblePages = (page, total) => {
  if (!page && !total) {
    return [1];
  }
  if (page === 0 && total === 0) {
    return [1];
  }
  if (total < 7) {
    return filterPages([1, 2, 3, 4, 5, 6], total);
  } else {
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [1, page - 1, page, page + 1, total];
    } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    } else {
      return [1, 2, 3, 4, 5, total];
    }
  }
};

const getTotalItemView = (totalItems, pageSize, pageIndex) => {
  pageIndex++;
  if (pageIndex * pageSize - pageSize > totalItems.countByFilter) {
    pageIndex = 1;
  }
  let from =
    pageIndex * pageSize - pageSize + 1 > totalItems.countByFilter
      ? 0
      : pageIndex * pageSize - pageSize + 1;
  let to =
    pageIndex * pageSize < totalItems.countByFilter
      ? pageIndex * pageSize
      : totalItems.countByFilter;

  return { from: from, to: to };
};

const getConfigColumnStyle = () => {
  return {
    boxSizing: "border-box",
    flex: "40 0 auto",
    width: 40,
    maxWidth: 40,
    minWidth: 40,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "flex",
  };
};
