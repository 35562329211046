import { useMemo } from "react";
import PropTypes from "prop-types";

import CheckboxOffIcon from "@images/checkbox/checkbox-off.svg";
import CheckboxOnIcon from "@images/checkbox/checkbox-on.svg";
import CheckboxOffDisabledIcon from "@images/checkbox/checkbox-off-disable.svg";
import CheckboxOnDisabledIcon from "@images/checkbox/checkbox-on-disable.svg";

const _nomarl = {
  display: "inline-block",
  verticalAlign: "middle",
  margin: 0,
  padding: 0,
  width: "16px",
  height: "16px",
  background: `url(${CheckboxOffIcon}) no-repeat`,
  border: "none",
  cursor: "pointer",
};

const _checked = {
  background: `url(${CheckboxOnIcon}) no-repeat`,
};

const _disabled = {
  background: `url(${CheckboxOffDisabledIcon}) no-repeat`,
  cursor: "default",
};

const disabled_checked = {
  background: `url(${CheckboxOnDisabledIcon}) no-repeat`,
  cursor: "default",
};

SntCheckBox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

function SntCheckBox({ checked, label, disabled, onChange }) {
  const _style = useMemo(() => {
    let rs = Object.assign({}, _nomarl);
    let _clss = "";
    if (checked) {
      rs = Object.assign(rs, _checked);
      _clss += "snt_checked";
    }
    if (disabled) {
      _clss += " snt_checkbox_disable";
      if (checked) {
        rs = Object.assign(rs, disabled_checked);
      } else {
        rs = Object.assign(rs, _disabled);
      }
    }

    return {
      style: rs,
      className: _clss,
    };
  }, [checked, disabled]);

  function onClick() {
    if (!disabled) {
      onChange && onChange(!checked);
    }
  }

  return (
    <div className="d-inline-block">
      <div
        className={`${_style.className} me-2`}
        style={_style.style}
        onClick={onClick}
      ></div>
      <span className="align-middle">{label || ""}</span>
    </div>
  );
}

export default SntCheckBox;
